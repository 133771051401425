import {
  Coverages,
  Deductibles,
  DetachedScreenEnclosure,
  DwellingInfo,
  Interview,
  Limits,
  LimitsNames,
  MissingInfo,
  OptionalCoverages,
  OtherStructures,
  Quote,
  WindMitigation,
} from 'src/models/model';
import { QuoteHO3, QuoteResponse, OptionalCoverage } from 'src/models/response';
import { currencyTransform } from './transforms';
import { PRODUCTS } from 'src/products/products';
import dayjs from 'dayjs';
import countries from './countries';

const { full } = countries;

const SPECIAL_LIMITS_SCENARIOS = ['AnimalLiablility'];

const getConstructionType = (construction: string) => {
  if (!construction) return undefined;
  if (
    [
      'Masonry',
      'Masonry Veneer/Fiber Cement',
      'Fire Resistive',
      'Reinforced Masonry',
      'Masonry Non-Combustible',
      'Non Combustible',
    ].includes(construction)
  )
    return 'Masonry';
  if (['Frame'].includes(construction)) return 'Frame';
  if (['Exterior Insulation and Finish System', 'Other'].includes(construction))
    return 'Other';
  return undefined;
};

export const quoteTransform = (quote: QuoteResponse): Partial<Quote> => {
  const MissingInfo = buildMissingInfo(quote);
  const Coverages = buildCoverages(quote);
  const Deductibles = buildDeductibles(quote);
  const Interview = buildInterview(quote);
  const MissingInfoFields = buildRequiredMissingInfo(quote);

  const PreviousCarrier = quote.Interview?.PreviousCarrier
    ? {
        PreviousCarrier: quote.Interview.PreviousCarrier,
        PriorExpirationDate: quote.Interview.PriorExpirationDate || undefined,
      }
    : undefined;

  const OptionalCoverages = buildOptionalCoverages(quote);

  const _quote: Partial<Quote> = {
    ID: quote.ID,
    QuoteNumber: quote.QuoteNumber,
    PolicyId: quote.PolicyId,
    PolicyNumber: quote.PolicyNumber,
    Property: { Location: quote.PropertyAddress },
    MailingAddress: {
      ...quote.MailingAddress,
      SameAsRisk:
        quote.MailingAddress?.Address1 === quote.PropertyAddress?.Address1,
    },
    Customer: quote.Customer,
    TotalFeesAndAssessment: quote.TotalFeesAndAssessment,
    TotalOptionalCoverages: quote.TotalOptionalCoverages,
    TotalSPPLimit: quote.TotalSPPLimit,
    ReplacementCost: quote.ReplacementCost,
    Premium: Number(quote.Premium),
    PackageType: quote.SelectedPackage,
    QualityGrades: quote.QualityGrades,
    MissingInfo: MissingInfo,
    Coverages: Coverages,
    Deductibles: Deductibles,
    PreviousCarrier: PreviousCarrier,
    OptionalCoverages: OptionalCoverages,
    Interview: Interview,
    Discounts: quote.Discounts,
    Underwriting: {
      ...quote.Underwriting,
      FirstPartyLimit: Number(quote.Underwriting?.FirstPartyLimit) ?? 0,
      ThirdPartyLimit: Number(quote.Underwriting?.ThirdPartyLimit) ?? 0,
    },
    Packages: quote.Packages,
    EffectiveDate: quote.Interview?.EffectiveDate ?? '',
    WindMitigation: quote.WindMitigation,
    SkipList: quote.SkipList,
    FurthestReached: quote.FurthestReached,
    ResumeFrom: quote.ResumeFrom,
    FirstName: quote.Customer.FirstName,
    LastName: quote.Customer.LastName,
    Email: quote.Customer.Email,
    State: quote.PropertyAddress?.State ?? 'FL',
    Phase: '',
    PartyId: '',
    FormType: quote.FormType,
    MissingInfoFields: MissingInfoFields ?? [],
  };

  if (quote.FirstMortgagee) {
    _quote.FirstMortgagee = {
      Type: 'FirstMortgagee',
      isAdditional: true,
      Name: quote.FirstMortgagee?.Name,
      PrimaryPhone: quote.FirstMortgagee?.PrimaryPhone,
      LoanNumber: quote.FirstMortgagee?.LoanNumber,
      Country: quote.FirstMortgagee?.Country,
      Address: quote.FirstMortgagee?.Address,
    };
  } else {
    _quote.FirstMortgagee = {
      Type: 'FirstMortgagee',
      isAdditional: false,
      Name: '',
    };
  }
  if (quote.SecondMortgagee) {
    _quote.SecondMortgagee = {
      Type: 'SecondMortgagee',
      isAdditional: true,
      Name: quote.SecondMortgagee.Name || '',
      PrimaryPhone: quote.SecondMortgagee.PrimaryPhone || '',
      LoanNumber: quote.SecondMortgagee.LoanNumber || '',
      Country: quote.SecondMortgagee.Country,
      Address: quote.SecondMortgagee.Address,
    };
  } else {
    _quote.SecondMortgagee = {
      Type: 'SecondMortgagee',
      isAdditional: false,
      Name: '',
    };
  }

  if (quote.Trust) {
    _quote.Trust = {
      Type: 'Trust',
      isAdditional: true,
      Name: quote.Trust.Name || '',
      PrimaryPhone: quote.Trust.PhoneNumber || '',
      AddressCountry: full.find((address) => {
        return (
          address.name ===
          (quote?.Trust?.Country === 'UnitedStatesOfAmerica'
            ? 'United States of America'
            : quote?.Trust?.Country)
        );
      })?.value,
      Address: quote.Trust.Address,
    };
  } else {
    _quote.Trust = {
      Type: 'Trust',
      isAdditional: false,
      Name: '',
    };
  }

  if (quote.CoApplicant) {
    _quote.CoApplicant = {
      AddCoApplicant: !!quote.CoApplicant,
      DateOfBirth: quote.CoApplicant.DateOfBirth,
      EmailAddress: quote.CoApplicant.Email,
      FirstName: quote.CoApplicant.FirstName,
      LastName: quote.CoApplicant.LastName,
      PrimaryPhone: quote.CoApplicant.Phone,
    };
  } else {
    _quote.CoApplicant = {
      AddCoApplicant: false,
    };
  }

  _quote.MissingInfo = MissingInfo;
  _quote.Coverages = Coverages;
  _quote.Deductibles = Deductibles;
  _quote.PreviousCarrier = PreviousCarrier;
  _quote.OptionalCoverages = OptionalCoverages;
  _quote.Interview = Interview;
  if (quote.FormType === 'HO-3') {
    const OtherStructures = buildOtherStructures(quote);
    const DwellingInfo = buildDwellingInfo(quote);
    _quote.OtherStructures = OtherStructures;
    _quote.DwellingInfo = DwellingInfo;
  }

  return _quote;
};

const buildRequiredMissingInfo = (quote: QuoteResponse): string[] => {
  const { MissingInfoFields, MissingInfo } = quote;
  const RequiredMissingInfo: string[] = MissingInfoFields ?? [];

  if (!MissingInfo) {
    return RequiredMissingInfo;
  }

  if (
    !(parseInt(MissingInfo?.YearBuilt) > 0) ||
    parseInt(MissingInfo?.YearBuilt) <= dayjs().add(-16, 'year').year()
  ) {
    RequiredMissingInfo.push('HotWaterHeater');
  }
  return RequiredMissingInfo;
};

const buildInterview = (quote: QuoteResponse): Interview => {
  return {
    FloorsAboveUnit: quote.Interview?.FloorsAboveUnit ?? '',
    OccupancyType: quote.Interview?.OccupancyType ?? '',
    UnitsAboveOrBelow: quote.Interview?.UnitsAboveOrBelow ?? false,
    SuperiorConstruction: quote.Interview?.SuperiorConstruction ?? false,
    HotWaterHeater: quote.Interview?.HotWaterHeater ?? null,
    FloorNumber: quote.Interview?.FloorNumber?.toString(),
    NumberOfStories: quote.Interview?.NumberOfStories?.toString(),
  };
};

const buildMissingInfo = (quote: QuoteResponse): MissingInfo | undefined => {
  if (!quote.MissingInfo) {
    return;
  }

  const { MissingInfo } = quote;

  const YearBuilt =
    MissingInfo.YearBuilt !== '0'
      ? Number.parseInt(MissingInfo.YearBuilt)
      : undefined;
  const RoofYear =
    MissingInfo.RoofYear !== '0'
      ? Number.parseInt(MissingInfo.RoofYear)
      : undefined;
  const SquareFeet =
    MissingInfo.SquareFeet !== '0'
      ? Number.parseInt(MissingInfo.SquareFeet)
      : undefined;

  const Construction = getConstructionType(MissingInfo.Construction);

  const { ExteriorWallFinish, DwellingType, RoofGeometry, RoofMaterial } =
    MissingInfo;

  return {
    YearBuilt,
    RoofYear,
    SquareFeet,
    Construction,
    DwellingType,
    ExteriorWallFinish,
    RoofGeometry,
    RoofMaterial,
  };
};

const buildCoverages = (quote: QuoteResponse): Coverages | undefined => {
  if (!quote.Coverages) {
    return;
  }
  const CoverageA = quote.Coverages.CoverageA?.Value ?? 0;
  const CoverageB = quote.Coverages.CoverageB?.Value ?? 0;
  const CoverageC = quote.Coverages.CoverageC?.Value ?? 0;
  const CoverageD = quote.Coverages.CoverageD?.Value ?? 0;
  const CoverageE = quote.Coverages.CoverageE;
  const CoverageF = quote.Coverages.CoverageF;

  return { CoverageA, CoverageB, CoverageC, CoverageD, CoverageE, CoverageF };
};

const HurricaneDeductibleMap: Record<string, string> = {
  '0': 'Excluded',
  '500': '$500',
  '1000': '$1,000',
  '1': '1%',
  '2': '2%',
  '5': '5%',
  '10': '10%',
};

const OtherPerilsMap: Record<string, string> = {
  '500': '$500',
  '1000': '$1,000',
  '2500': '$2,500',
  '5000': '$5,000',
  '7500': '$7,500',
  '10000': '$10,000',
};

const buildDeductibles = (quote: QuoteResponse): Deductibles | undefined => {
  if (!quote.Deductibles) {
    return;
  }

  const HurricaneDeductible =
    HurricaneDeductibleMap[quote.Deductibles.HurricaneDeductible] ??
    'Invalid amount';

  const OtherPerils: string =
    OtherPerilsMap[currencyTransform.output(quote.Deductibles.OtherPerils)] ??
    'Invalid amount';

  return { HurricaneDeductible, OtherPerils };
};

const buildOtherStructures = (quote: QuoteHO3): OtherStructures => {
  const hasOnPremises =
    quote.OtherStructures?.OnPremises &&
    quote.OtherStructures?.OnPremises?.length > 0;

  const OnPremises = hasOnPremises
    ? quote.OtherStructures?.OnPremises.map((item) => {
        const Id = item.Id;
        const Limit =
          Number(item.Limit) === 0
            ? 0
            : Math.ceil(
                (Number(item.Limit) / quote.Coverages.CoverageA.Value) * 100
              );

        const OtherDescription = item?.Description?.includes('Other -')
          ? item.Description.replace('Other - ', '')
          : undefined;
        const Description = item?.Description?.includes('Other -')
          ? 'Other'
          : item.Description;
        return { Id, Limit, OtherDescription, Description };
      })
    : [];

  const hasRentedToOthers =
    quote.OtherStructures?.RentedToOthers &&
    quote.OtherStructures?.RentedToOthers?.length > 0;

  const RentedToOthers = hasRentedToOthers
    ? quote.OtherStructures?.RentedToOthers.map((item) => {
        const Id = item.Id;
        const Limit =
          Number(item.Limit) === 0
            ? 0
            : Math.ceil(
                (Number(item.Limit) / quote.Coverages.CoverageA.Value) * 100
              );

        const OtherDescription = item?.Description?.includes('Other -')
          ? item.Description.replace('Other - ', '')
          : undefined;
        const Description = item?.Description?.includes('Other -')
          ? 'Other'
          : item.Description;
        return { Id, Limit, OtherDescription, Description };
      })
    : [];

  return { OnPremises, RentedToOthers, hasOnPremises, hasRentedToOthers };
};

export const buildWindMitigation = (
  quote: QuoteResponse
): WindMitigation | undefined => {
  if (quote.WindMitigation?.RoofCover) {
    return;
  }
  const { WindMitigation } = quote;
  return {
    RoofCover: windMitFormMapping.RoofCover[WindMitigation.RoofCover],
    RoofDeckAttachment:
      windMitFormMapping.RoofDeckAttachment[WindMitigation.RoofDeckAttachment],
    RoofToWallConnection:
      windMitFormMapping.RoofToWallConnection[
        WindMitigation.RoofToWallConnection
      ],
    RoofGeometry: windMitFormMapping.RoofGeometry[WindMitigation.RoofGeometry],
    SecondaryWaterResistance:
      windMitFormMapping.SecondaryWaterResistance[
        WindMitigation.SecondaryWaterResistance
      ],
    OpeningProtection:
      windMitFormMapping.OpeningProtection[WindMitigation.OpeningProtection],
  };
};

const buildOptionalCoverages = (
  quote: QuoteResponse
): OptionalCoverages | undefined => {
  const Limits: Partial<Limits> = {};
  if (!quote.OptionalCoverages) {
    return;
  }
  const limitsNamesSet = new Set<string>([
    'WaterBackupAndSumpOverflow',
    'EquipmentBreakdown',
    'ServiceLine',
    'AnimalLiablility',
    'PersonalInjury',
    'PersonalPropertyReplacementCost',
    'UnitOwnersSpecialCoverage',
    'UnitOwnersCoverageA',
    'GolfCartPhysical',
    'ScreenEnclosureHurricane',
    'OrdinanceOrLaw',
  ]);

  (Object.keys(quote.OptionalCoverages ?? {}) as LimitsNames[]).forEach(
    (key) => {
      if (!limitsNamesSet.has(key)) {
        return;
      }

      const optCoverage = quote.OptionalCoverages[key] as OptionalCoverage;
      if (
        typeof optCoverage === 'object' &&
        optCoverage.Premium !== undefined &&
        (optCoverage.Premium > 0 ||
          (key === 'OrdinanceOrLaw' && Number(optCoverage?.Limit) > 0))
      ) {
        if (SPECIAL_LIMITS_SCENARIOS.includes(key)) {
          const { Premium, Value, ...subLimits } = optCoverage;
          if (subLimits.Limits) {
            Limits[key as LimitsNames] = { ...subLimits.Limits };
          } else {
            Limits[key as LimitsNames] = subLimits;
          }
        } else {
          Limits[key as LimitsNames] = optCoverage.Limit ?? 0;
        }
      }
    }
  );

  const optionalCoverages: OptionalCoverages = {
    WaterBackupAndSumpOverflow:
      quote.OptionalCoverages?.WaterBackupAndSumpOverflow?.Value,
    EquipmentBreakdown: quote.OptionalCoverages?.EquipmentBreakdown?.Value,
    ServiceLine: quote.OptionalCoverages?.ServiceLine?.Value,
    AnimalLiablility: quote.OptionalCoverages?.AnimalLiablility?.Value,
    PersonalInjury: quote.OptionalCoverages?.PersonalInjury?.Value,
    PersonalPropertyReplacementCost:
      quote.OptionalCoverages?.PersonalPropertyReplacementCost?.Value,
    UnitOwnersSpecialCoverage:
      quote.OptionalCoverages?.UnitOwnersSpecialCoverage?.Value,
    UnitOwnersCoverageA: quote.OptionalCoverages?.UnitOwnersCoverageA?.Value,
    GolfCartPhysical: quote.OptionalCoverages?.GolfCartPhysical?.Value,
    ...(quote.FormType === PRODUCTS.FLORIDA_HO3.FormType
      ? {
          ScreenEnclosureHurricane:
            quote.OptionalCoverages?.ScreenEnclosureHurricane?.Value,
        }
      : {}),
    OrdinanceOrLaw: quote.OptionalCoverages?.OrdinanceOrLaw?.Value,
    Limits: Limits,
  };
  return optionalCoverages;
};
const buildDwellingInfo = (quote: QuoteHO3): DwellingInfo | undefined => {
  if (!quote.DwellingInfo) {
    return undefined;
  }

  const { AttachedScreenEnclosure, DetachedScreenEnclosure, Pool } =
    quote.DwellingInfo;

  const newAttachedScreenEnclosure: DetachedScreenEnclosure = {
    IsOnPremises: false,
  };
  if (AttachedScreenEnclosure) {
    const SizeSqFt = Number(AttachedScreenEnclosure.SizeSqFt);

    newAttachedScreenEnclosure.IsOnPremises =
      AttachedScreenEnclosure.IsOnPremises;
    newAttachedScreenEnclosure.BuiltOverPool =
      AttachedScreenEnclosure.BuiltOverPool;
    newAttachedScreenEnclosure.Height = AttachedScreenEnclosure.Height;
    newAttachedScreenEnclosure.SizeSqFt = SizeSqFt > 0 ? SizeSqFt : undefined;
  }
  const newDetachedScreenEnclosure: DetachedScreenEnclosure = {
    IsOnPremises: false,
  };
  if (DetachedScreenEnclosure) {
    const SizeSqFt = Number(DetachedScreenEnclosure.SizeSqFt);

    newDetachedScreenEnclosure.IsOnPremises =
      DetachedScreenEnclosure.IsOnPremises;
    newDetachedScreenEnclosure.BuiltOverPool =
      DetachedScreenEnclosure.BuiltOverPool;
    newDetachedScreenEnclosure.Height = DetachedScreenEnclosure.Height;
    newDetachedScreenEnclosure.SizeSqFt = SizeSqFt > 0 ? SizeSqFt : undefined;
  }

  return {
    AttachedScreenEnclosure: newAttachedScreenEnclosure,
    DetachedScreenEnclosure: newDetachedScreenEnclosure,
    Pool: Pool,
  };
};

const windMitFormMapping: {
  RoofCover: Record<string, string>;
  RoofDeckAttachment: Record<string, string>;
  RoofToWallConnection: Record<string, string>;
  RoofGeometry: Record<string, string>;
  SecondaryWaterResistance: Record<string, string>;
  OpeningProtection: Record<string, string>;
} = {
  RoofCover: {
    'A': 'FBC Equivalent',
    'B': 'FBC Equivalent',
    'C': 'Non-FBC Equivalent',
    'D': 'Non-FBC Equivalent',
    'Inspector Did Not Specify': 'Unknown',
    'Level B': 'FBC Equivalent',
    'Level A': 'Non-FBC Equivalent',
  },
  RoofDeckAttachment: {
    'A': `6d @ 6"/12`,
    'B': `8d @ 6"/12`,
    'C': `8d @ 6"/6" Dimensional Lumber Deck`,
    'D': 'Reinforced Concrete Roof Deck',
    'E': 'Unknown',
    'F': 'Unknown',
    'G': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Level A': `6d @ 6"/12`,
    'Level B': `8d @ 6"/12`,
    'Level C': 'Reinforced Concrete Roof Deck',
  },
  RoofToWallConnection: {
    'A': 'Toe Nails',
    'B': 'Clips',
    'C': 'Single Wraps',
    'D': 'Double Wraps',
    'E': 'Reinforced Concrete Roof Deck',
    'F': 'Unknown',
    'G': 'Unknown',
    'H': 'Unknown',
    'Inspector Did Not Specify': 'N/A',
  },
  RoofGeometry: {
    'A': 'Hip',
    'B': 'Flat',
    'C': 'Gable',
    'Inspector Did Not Specify': 'Other',
  },
  SecondaryWaterResistance: {
    'A': 'Yes SWR',
    'B': 'No SWR',
    'C': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Underlayment': 'Yes SWR',
    'Foamed Adhesive': 'No SWR',
  },
  OpeningProtection: {
    'A.1': 'Hurricane - Windows or All',
    'A.2': 'Hurricane - Windows or All',
    'A.3': 'Hurricane - Windows or All',
    'B.1': 'Basic - Windows or All',
    'B.2': 'Basic - Windows or All',
    'B.3': 'Basic - Windows or All',
    'C.1': 'None',
    'C.2': 'None',
    'C.3': 'None',
    'N.1': 'None',
    'N.2': 'None',
    'N.3': 'None',
    'X': 'None',
    'Inspector Did Not Specify': 'Unknown',
    'Class A (Hurricane Impact)': 'Hurricane - Windows or All',
    'Class B (Basic Impact)': 'Basic - Windows or All',
  },
};
