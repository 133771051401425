import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useFeatureFlags, useModel } from 'src/lib/hooks';
import Landing from 'src/views/Landing';
import Login from 'src/views/Login';
import Onboarding from 'src/views/Onboarding';
import Campaign from 'src/views/Campaign';
import Resume from 'src/views/Resume';
import Maintenance from 'src/views/Maintenance';
import Pause from './views/Pause';
import UnsuportedState from './views/UnsuportedState';
import AccountUnblocked from './views/AccountUnblocked';
import config from './config';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import useInterval from './lib/hooks/useInterval';

datadogRum.init({
  applicationId: config.datadog.appId,
  clientToken: config.datadog.clientToken,
  site: 'us5.datadoghq.com',
  service: 'slidedtcdev',
  env: config.datadog.env,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogLogs.init({
  clientToken: config.datadog.clientToken,
  site: 'us5.datadoghq.com',
  service: 'slidedtcdev',
  env: config.datadog.env,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

const App = () => {
  const { enableCampaignRoute, maintenanceMode } = useFeatureFlags();
  const { serverIsDown } = useModel.userInterface();
  const { checkServer } = useModel.userInterface.dispatch();

  useInterval(
    () => checkServer(),
    config.healthCheck.timeout,
    config.healthCheck.isEnabled
  );

  React.useEffect(() => {
    checkServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (maintenanceMode || serverIsDown) {
    return <Maintenance />;
  }
  return (
    <Switch>
      <Route path="/onboarding" component={Onboarding} />
      {enableCampaignRoute && (
        <Route exact path="/campaign" component={Campaign} />
      )}
      <Route exact path="/login" component={Login} />
      <Route exact path="/resume" component={Resume} />
      <Route exact path="/pause" component={Pause} />
      <Route exact path="/unsupported" component={UnsuportedState} />
      <Route exact path="/account-unblocked" component={AccountUnblocked} />
      <Route path="/" component={Landing} />
    </Switch>
  );
};

export default App;
