import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import config from 'src/config';
import addReferralHandler from 'src/lib/addReferralHandler';
import store from 'src/lib/store';

interface Options extends AxiosRequestConfig {
  headers?: Record<string, string>;
}

export const getHeaders = (
  options: Options = {},
  secure = false
): Record<string, string> => {
  const headers = { ...options.headers };

  headers['client_id'] = config.slide.clientId || '';
  headers['client_secret'] = config.slide.clientSecret || '';

  if (secure) {
    const token = store.getState().user?.token;
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const mockApi = {
  putProfileMockCall: (): Promise<void> =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  patchProfileMockCall: (): Promise<void> =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  postInterestMockCall: (): Promise<void> =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
};

const secure = {
  get: <TResponse>(
    path: string,
    options: Options = {}
  ): Promise<AxiosResponse<TResponse>> =>
    addReferralHandler(
      api.get<TResponse>('/secure' + path, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  post: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      api.post('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  put: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      api.put('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  patch: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      api.patch('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  delete: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      api.delete('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  ...mockApi,
};

const api = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: <TResponse = any>(
    path: string,
    options: Options = {}
  ): Promise<AxiosResponse<TResponse>> =>
    addReferralHandler(
      axios.get<TResponse>(config.slide.baseUrl + path, {
        ...options,
        headers: getHeaders(options),
        timeout: config.requestTimeout,
      })
    ),
  post: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      axios.post(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
        timeout: config.requestTimeout,
      })
    ),
  put: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      axios.put(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
        timeout: config.requestTimeout,
      })
    ),
  patch: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      axios.patch(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
        timeout: config.requestTimeout,
      })
    ),
  delete: (
    path: string,
    body: unknown,
    options: Options = {}
  ): Promise<AxiosResponse> =>
    addReferralHandler(
      axios.delete(config.slide.baseUrl + path, {
        ...options,
        headers: getHeaders(options),
        data: body,
        timeout: config.requestTimeout,
      })
    ),
  ...mockApi,
  secure: { ...secure },
  fakeLoad: <T>(data: T): Promise<{ data: T }> =>
    new Promise((resolve) => {
      setTimeout(() => resolve({ data }), 1000);
    }),
};

export { api };

const useApi = (): typeof api => api;

export default useApi;
