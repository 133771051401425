/* eslint-disable no-console */
import axios from 'axios';
import { ErrorTypes } from 'src/products/shared/constants';
import store from './store';

const axiosErrorHandler = async (error) => {
  if (error === null) {
    throw new Error('Unrecoverable error!! Error is null!');
  }
  if (axios.isAxiosError(error)) {
    await store.dispatch.userInterface.checkServer();

    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..');
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..');
    }
  }

  //Something happened in setting up the request and triggered an Error
  console.log(error.message);
  throw error;
};

// eslint-disable-next-line no-unused-vars
const verifyReferral = (resp) => {
  if (
    resp.data?.Status === ErrorTypes.REFERRED_TO_INT_AGENT ||
    resp.data?.Status === ErrorTypes.REFERRED_TO_EXT_AGENT
  ) {
    const error = new Error(resp.data.Status);
    error.statusCode = 422;
    error.errorType = resp.data.Status;
    error.message = 'Referred to Agent';
    error.name = 'Error';
    throw error;
  }
  return resp;
};
const addReferralHandler = (resp) => {
  if (resp) {
    return resp
      .catch((error) => {
        if (error.response) {
          verifyReferral(error.response);
        }

        axiosErrorHandler(error);
      })
      .then(verifyReferral);
  }
};
export default addReferralHandler;
