import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isEmail from 'validator/lib/isEmail';

dayjs.extend(utc);

const dateFormat = 'MM/DD/YYYY';
const validEmailRegex = new RegExp(
  '^[\\w\\d\\.\\-\\_\\+]+@[\\w\\d\\.\\-\\_\\+]+$'
);

export const parseDate = (value) => {
  const date = dayjs(value);

  if (!date.isValid()) {
    return null;
  }

  return date.format('MM/DD/YYYY') === value ||
    date.format('YYYY-MM-DD') === value
    ? date
    : null;
};

const defaultMessagesBirthDate = {
  invalidDate: 'Invalid date.',
  tooOld: (minDate) =>
    `Date of Birth must be after ${minDate.format(dateFormat)}.`,
  tooYoung: 'You must be at least 18 years old to get a quote',
};

const CoApplicantMessagesBirthDate = {
  invalidDate: 'Invalid date.',
  tooOld: (minDate) =>
    `Date of Birth must be after ${minDate.format(dateFormat)}.`,
  tooYoung: 'Must be at least 18 years old.',
};

const baseValidateBirthDate = (value, minAge, maxAge, messages) => {
  const date = parseDate(value);

  if (date === null) {
    return messages.invalidDate;
  }

  let year = dayjs(value).year();
  if (year < 1000) {
    return messages.invalidDate;
  }

  const minDate = dayjs().subtract(maxAge, 'year');
  const maxDate = dayjs().subtract(minAge, 'year');

  const tooYoung = date.isAfter(maxDate);
  const tooOld = date.isBefore(minDate);

  if (tooOld) {
    return messages.tooOld(minDate);
  }

  if (tooYoung) {
    return messages.tooYoung;
  }

  return true;
};

export const validateBirthDate = (value, minAge = 18, maxAge = 99) =>
  baseValidateBirthDate(value, minAge, maxAge, defaultMessagesBirthDate);

export const validateCoApplicantBirthDate = (value, minAge = 18, maxAge = 99) =>
  baseValidateBirthDate(value, minAge, maxAge, CoApplicantMessagesBirthDate);

export const validateExpression = (expresion, errorMessage) => {
  return expresion || errorMessage;
};

export const validateYear = (year) => {
  let currentYear = dayjs().year();
  if (year <= 0 || !dayjs(year, 'YYYY').isValid()) {
    return 'Must be a valid year.';
  }
  if (year < 1900 || year > currentYear) {
    return `Must be between 1900 and ${currentYear}.`;
  }
  return true;
};

export const validateEffectiveDate = (value) => {
  const currentDate = dayjs().startOf('day');
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  const daysInFuture = 60;

  const dateDiff = date.diff(currentDate, 'day', true);

  const tooSoon = dateDiff < 0;
  const tooLate = dateDiff > daysInFuture;

  if (tooSoon || tooLate) {
    return `Must be within the next ${daysInFuture} days.`;
  }

  const currentDateUtc = dayjs().utc();
  const utcHours = currentDateUtc.hour();
  const dateHourDiffUtc = utcHours - dayjs().hour();
  if (dateDiff === 0 && (utcHours >= 21 || dateHourDiffUtc < 0)) {
    return 'Please select a date in the future.';
  }
  const minDay = dayjs('09/01/2021', dateFormat, true);

  if (minDay.isAfter(value)) {
    return 'Must be on or after 9/1/2021.';
  }

  return true;
};

export const validateInspectionDate = (value) => {
  const currentDate = dayjs();
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  const tooSoon = date.diff(currentDate, 'year', true) < -5;
  const tooLate = date.diff(currentDate, 'day', true) > 0;

  if (tooSoon || tooLate) {
    return 'Must be within the last 5 years.';
  }

  return true;
};

export const validateEmail = (value) => {
  if (!isEmail(value)) {
    return 'Invalid email.';
  }

  if (!validEmailRegex.test(value)) {
    return 'Invalid email.';
  }

  return true;
};

export const validateHighRiseFloorNumber = (value) => {
  if (value < 1 || value > 99) {
    return 'Number must be between 1 and 99.';
  }
  return true;
};

export const validateLowRiseFloorNumber = (value) => {
  if (value < 1 || value > 6) {
    return 'Invalid floor.';
  }
  return true;
};

export const validatePriorExpirationDate = (value) => {
  const date = parseDate(value);
  const minDate = dayjs('01/01/1900', dateFormat, true);
  const maxDate = dayjs().subtract(1, 'day').add(1, 'year');

  if (date === null) {
    return 'Invalid date.';
  }

  if (date.isBefore(minDate) || date.isAfter(maxDate)) {
    return `Must be between 01/01/1900 and ${maxDate.format(dateFormat)}.`;
  }

  return true;
};

export const validateMonthAndYear = (monthYear) => {
  let customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  const [month, year] = monthYear.split('/');
  const date = `${year}/${month}/14`;
  const currentDate = dayjs().startOf('month');
  if (dayjs(date, 'YYYY/MM/DD', true).isValid()) {
    if (currentDate.isAfter(date)) {
      return 'Must be in the future.';
    }
    return true;
  } else {
    return `Invalid Value.`;
  }
};
