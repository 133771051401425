import React, { PropsWithChildren } from 'react';
import Header from '../Header';
import { twMerge } from '../../lib/mergeTailwind';

type Props = {
  className?: string;
  displayNavElements?: boolean;
  displayConfetti?: boolean;
};

const BasicLayout = ({
  children,
  className = '',
  displayNavElements,
  displayConfetti = false,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={twMerge('flex flex-col mx-auto max-w-2xl pb-4', className)}
      {...rest}
    >
      <Header displayConfetti={displayConfetti} />
      {children}
    </div>
  );
};

export default BasicLayout;
