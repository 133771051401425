import React from 'react';
import * as images from 'src/assets/img';

export type ImageName = keyof typeof images | 'none';

interface ImgProps {
  src: ImageName;
  alt?: string;
  className?: string;
  role?: string;
}

const Img: React.FC<ImgProps> = ({
  src,
  alt = '',
  className = '',
  ...rest
}) => {
  if (src === 'none' || !images[src]) {
    return null;
  }

  const ImageComponent = images[src];
  return <img src={ImageComponent} alt={alt} className={className} {...rest} />;
};

export default Img;
