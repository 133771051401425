import merge from 'lodash.merge';
import { UserInterface } from './model';
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import axios from 'axios';
import config from 'src/config';
import { getHeaders } from 'src/lib/hooks/useApi/useApi';

const _userInterface = createModel<RootModel>()({
  state: { isLoading: false, serverIsDown: false },
  reducers: {
    'setInterface': (state: UserInterface, update: Partial<UserInterface>) =>
      merge({}, state, update),
    'global/reset': (state: UserInterface) => ({
      isLoading: false,
      serverIsDown: state?.serverIsDown,
    }),
  },
  effects: (dispatch) => ({
    save: () => {},
    checkServer: async () => {
      try {
        const response = await axios.get(
          config.slide.baseUrl + `/quote/health-check`,
          { headers: getHeaders() }
        );
        if (response.status === 200) {
          dispatch.userInterface.setInterface({ serverIsDown: false });
        } else {
          dispatch.userInterface.setInterface({ serverIsDown: true });
          dispatch({ type: 'global/reset' });
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        dispatch.userInterface.setInterface({ serverIsDown: true });
        dispatch({ type: 'global/reset' });
      }
    },
  }),
});

export default _userInterface;
