import React from 'react';
import config from 'src/config';
import { twMerge } from 'tailwind-merge';

const ProgressBar = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const totalDuration = config.requestTimeout;
    const intervalDuration = 100; // 0.1 second
    const incrementValue = 100 / (totalDuration / intervalDuration); // Calculate increment value

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + incrementValue;
      });
    }, intervalDuration);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-[40%] mt-8 h-4 bg-gray-300 rounded-full overflow-hidden flex">
      <span
        style={{
          width: `${progress}%`,
        }}
        className={twMerge(
          'h-5 bg-violet rounded-l-full',
          progress > 99 ? 'rounded-r-full' : ''
        )}
      />
      <span
        className={twMerge(
          '-ml-4 -mt-4 w-8 h-8 rotate-45 bg-violet',
          progress > 99 ? 'hidden' : ''
        )}
      />
    </div>
  );
};

export default ProgressBar;
