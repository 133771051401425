import { useCallback, useEffect, useRef } from 'react';

export default function useInterval(
  callback: () => void,
  delay: number,
  isEnabled: boolean = true
) {
  const callbackRef = useRef(callback);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    if (isEnabled) {
      intervalRef.current = setInterval(() => callbackRef.current(), delay);
    }
  }, [delay, isEnabled]);

  const clear = useCallback(() => {
    intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear, isEnabled]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}
